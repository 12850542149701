.snackbar {
    position: fixed;
    bottom: 16px;
    left: 12rem;
    transform: translateX(-50%);
    background-color: #d32f2f;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

  }
  
  .snackbar.show {
    opacity: 1;
  }
  .snackbarText{
      color: white;
      font-weight: 600;
      font-size: 15px;
      margin-left: 15px;
      
  }
  .snackBarContainer{
      width: 94%;
      display: flex;
      align-items: center;
      flex-direction: row;
  }
  .CrossArrow{
    height: 25px;
    width: 25px;
    margin-left: 35px;
    cursor: pointer;
  }
  .rightArrowButton {
    height: 30px;
    width: 30px;
    margin-left: 5px;
  }