.spinner {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  border-top-color: #333;
  animation: spinner-rotate 1s infinite linear;
  margin-left: 10px;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
