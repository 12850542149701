/* .App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  background-color: #428bca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.containeLoader {
  position: fixed;
  height: 100vh;
  width: 100wh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;


}
.loader {
  border: 9px solid #f3f3f3; /* Light grey */
  border-top: 9px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.top-container {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.bottom-container {
  height: 11vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  background-color: #428bca;
}

.input-container {
  border: 2px solid white;
  width: 85%;
  height: 3rem;
  display: flex;
  flex-direction: row;
}

.input {
  width: 90%;
  height: 100%;
  background: transparent;
  color: white;
  font-size: large;
  border: none;
  outline: none;
  padding-left: 15px;
}
::placeholder {
  color: white;
  opacity: 1;
}
.arrow-container {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBorder {
  border: 1px solid rgb(91, 89, 89);
  width: 80vw;
  border-radius: 20px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
}

::-webkit-scrollbar {
  display: none;
}

.img-small {
  cursor: pointer;
}

.response-right-container {
  width: 90%;
  height: 100%;
  padding: 20px;
}

.thumbs-container {
  width: 10%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.thumbs-image {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

.thumbs-image:hover {
  background-color: rgb(100, 98, 98);
  border-radius: 5px;
}

.top-sub-container {
  flex-direction: column;
  display: flex;
  /* overflow-y: scroll; */
  /* height: 99%;
  width:100%; */
  
  
}

.thumb-container {
  display: flex;
  flex-direction: column;
}

.logout-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  align-self: flex-end;
  margin-right: 8px;
  margin-bottom: 5px;
}

.logout-button:hover {
  background-color: #0563b6;
}

.logout-button:focus {
  outline: none;
}

.donthave-text {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 17px;
  margin-top: 10px;
  color: black;
}

.signup-text {
  cursor: pointer;
  padding-left: 8px;
  font-size: 13px;
  color: black;
  display: flex;
  justify-content: center;
}
.forget-password {
  color: #007bff;
  margin-left: 6px;
}
.dont-have-account-text {
  color: #007bff;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

form > div {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border: 5px;
}

button:hover {
  background-color: #0056b3;
}

span {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

.sign-up-container {
  height: 500px;
  width: 60%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container-signup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.name-container {
  width: 50%;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.input-subcontainer-signup {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.error-text {
  display: flex;
  justify-content: flex-start;
}

.input-div {
  height: 20px;
  width: 300px;
}
.forget-main-container {
  height: 24rem;
  width: 40%;
  background-color: white;
}
.forget-header {
  width: 100%;
  height: 25%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: black;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 200;
}
.forget-email-text {
  color: black;
  font-size: 13px;
  margin-bottom: 2px;
  width: 96%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.forget-email-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.forget-email-box {
  width: 95%;
  height: 28px;
}
.back-to-login {
  font-size: 14px;
  color: black;
  margin-top: 17px;
  color: #007bff;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  width: 96%;
  align-items: center;
  padding-left: 3px;
}
.back-to-login-container {
  cursor: pointer;
  align-items: center;
  display: flex;
}
.forget-submit-button {
  width: 96%;
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.forget-bottom-text {
  width: 100%;
  margin-top: 15px;
  height: 5rem;
  background-color: rgb(235, 239, 239);
  display: flex;
  justify-content: center;
  align-items: center;
}
.forget-bottom-text-subcontainer {
  width: 80%;
  font-size: 1rem;
  color: black;
  line-height: 1.4;
  word-spacing: 0.5px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftarrow-image {
  height: 13px;
  width: 13px;
  margin-right: 3px;
}
.errorConfirm {
  color: red;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  width: 96%;
}
.youneed-container {
  height: 60px;
  width: 94%;
  background-color: rgb(252, 238, 213);
  border-top: 2px solid rgb(137, 90, 3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  color: brown;
  flex-direction: row;
}
.forget-main-container-update {
  height: 27rem;
  width: 40%;
  background-color: white;
}
.forget-header-update {
  width: 100%;
  height: 22%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: black;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 200;
}
.warningarrow-icon {
  height: 17px;
  width: 17px;
  margin-right: 3px;
}
.forget-error {
  color: red;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 96%;
  height: 25px;
}
.login-container {
  background-color: white;
  height: 30rem;
  width: 25rem;
}
.login-heading {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bff;
  font-weight: 600;
}
.Login-button-conatiner {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login-button {
  width: 8rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login-email-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 65px;
}
.login-email-box {
  width: 80%;
  height: 28px;
}
.login-email-text {
  color: black;
  font-size: 13px;
  margin-bottom: 2px;
  width: 83%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 600;
  margin-bottom: 5px;
}
.showPassword {
  color: black;
  font-size: 12px;
}
.checkboxSubContainer {
  display: flex;
  flex-direction: row;
  width: 82%;
}
.check-box-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.error-password-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.error-password {
  color: red;
  font-size: 10px;
  width: 80%;
}
.signup-email-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.error-password-signup {
  color: red;
  font-size: 10px;
  width: 80%;
}
.signup-container {
  background-color: white;
  height: 36rem;
  width: 25rem;
}
.gifStyle {
  height: 20px;
  width: 50px;
}
.gifStyle2 {
  height: 20rem;
  width: 20rem;
}
.submit-button {
  display: flex;
  flex-direction: row;
  background-color: white;
  color: #007bff;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  border: none;
}
.submit-button:hover {
  background-color: rgb(205, 202, 202);
}
.right-arrow-button {
  height: 30px;
  width: 30px;
  margin-left: 5px;
}
.error-icon {
  height: 30px;
  width: 30px;
  margin-right: 14px;
}
.login-header {
  background-color: white;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: -10px;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
}
.header-assistant {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: larger;
}
.error-container {
  color: black;
  font-size: 20px;
  background-color: white;
  height: 50px;
  width: 50rem;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
  flex-direction: row;
}
.feedback-container {
  display: flex;
  justify-content: flex-end;
}
.button-feedback {
  flex-direction: row;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(1, 8, 14);
  margin-right: 2px;
}
.feedback-image {
  height: 1.3rem;
  width: 1.3rem;
  cursor: pointer;
  margin-left: 15px;
}
.arrow-containerGif{
  width: 60%;
  height: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-email-box::placeholder {
  color: rgba(0, 0, 0, 0.717);
}

.button-signup{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 2rem;
}
