.darkBG {
    background-color: rgba(0, 0, 0, 0.158);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal {
    width: 1000px;
    height: 350px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    
  }
  
  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 26px;
    display: flex;
    padding-left: 30px;
    padding-top: 20px;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }
  
  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }
  
  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 5px;
    font-size: 0.8rem;
    color: white;
    background: #007bff;
    transition: all 0.25s ease;
    font-weight: bold;
  }
  
  .cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
    padding: 11px 28px;
    border-radius: 5px;
    font-size: 0.8rem;
    color: white;
    background: #007bff;
    transition: all 0.25s ease;
    margin-bottom: 25px;
  }
  
  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: #0060c7;
  }
  .line{
      height: 1px;
      width: 100%;
      background-color: rgb(179, 174, 174);
      margin-top: 30px;
  }
  .inputContainer {
    border: 1px solid black;
    width: 85%;
    height: 60px;
    display: flex;
    flex-direction: row;
    display: flex;
  }
  
  .input {
    width: 90%;
    height: 100%;
    background: transparent;
    color: black;
    font-size: large;
    padding-left: 15px;
    border: none;
    outline: none;
  }
  .input::placeholder {
    color: black;
  }
  .imgSmall{
    cursor:pointer ;
  }
  .inputMainContainer{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
  }
  .thumbsImage{
    height:30px;
    width:30px;
    margin-top: 7px;
    border-radius: 30px;
    padding: 5px;
  }
